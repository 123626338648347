@include media-breakpoint-up(md) {
  .proquinal-footer {
    text-align: left;
  }

  .footer-links {
    a {
      &:first-child {
        padding-top: 0;
      }
    }
  }

  .footer-copyright-info {
    text-align: center;
  }
}