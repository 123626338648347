@include media-breakpoint-down(sm) {
  .specs-table-row--in-compare-page {
    th {
      display: none;
    }
  }

  .specs-table-row--in-product-detail-page {
    th,
    td {
      display: block;
      width: 100%;
      border: none;
    }

    th {
      padding-bottom: .2rem;
    }

    td {
      padding-top: .2rem;
    }
  }
}