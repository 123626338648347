.proquinal-footer {
  background-color: $gray-dark;
  color: white;
  text-align: center;
  padding: 3rem 0;
  font-size: $font-size-sm;

  a {
    color: $footer-links-color;
    @include transition($transition-base);

    &:hover {
      color: white;
      text-decoration: none;
    }
  }

  button {
    padding: 0;
    color: $footer-links-color;
    @include transition($transition-base);

    &:hover {
      color: white;
      text-decoration: none;
    }
  }
}

.footer-section-title {
  font-size: $font-size-sm;
  margin-bottom: 1rem;
}

.footer-contact-info {
  a {
  }
}

.footer-spradling-links {
  margin-bottom: 2rem;

  a {
    display: block;
    padding: 0.1rem 0;
  }
}

.footer-links {
  margin-bottom: 2rem;

  a {
    display: block;
    padding: 0.1rem 0;
  }
}

.footer-social-links {
  margin-bottom: 3rem;

  a {
    display: block;
    padding: 0.1rem 0;
  }
}

.footer-copyright-info {
  img {
    height: $footer-logo-height;
    margin-bottom: 0.5rem;
  }

  p {
    color: $footer-links-color;
  }
}
