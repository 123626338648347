@include media-breakpoint-up(sm) {
  .home-latest-releases {
    &:after {
      content: "";
      display: block;
      width: 50vw;
      height: 100%;
      background: url("../img/background.jpg") no-repeat center center transparent;
      background-size: cover;
      position: absolute;
      left: 100%;
      top: 0;
    }
  }
}