.form-control {
  border-radius: 0;
}

.form-text {
  a {
    color: $orange;
  }
}

.invalid-feedback {
  // I'm overriding the default behavior bootstrap has, because they require you to add
  // .was-validated class to the form, but it does not apply for redux form because it
  // validates field by field as these lose focus
  display: block;
}